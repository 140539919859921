import React from 'react';
import { Link } from 'gatsby';
import Img from '../components/img-wrapper';
import TextRow from '../components/text-row';
import Breadcrumb from './breadcrumb';

const ChiSonoTemplate = ({ data }) => (
  <>
    <div className="section mt-0">
      <div className="container-xl">
        <div className="row mb-3">
          <div className="col-12">
            <Breadcrumb breadcrumb={[{ name: 'Chi sono' }]} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h1>{data.title}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Img image={data.profile} className="rounded-image" alt="Francesco Loffredo" />
          </div>
        </div>
      </div>
    </div>
    {data.paragraphs &&
      data.paragraphs.map((item, i) => <TextRow {...item} dark={i % 2 === 0} key={i} />)}
    <div className="section">
      <div className="container-xl">
        <div className="row">
          <div className="col">
            <Link to="/">Torna alla home</Link>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default ChiSonoTemplate;
