import React from 'react';

const TextRow = ({ title, hideTitle, left, right, dark }) => (
  <div className={`section${dark ? ` section--dark` : ``}`}>
    <div className="container-xl">
      {title && !hideTitle && (
        <div className="row">
          <div className="col">
            <h3 className="section__title">{title}</h3>
          </div>
        </div>
      )}
      <div className="row">
        <div
          className={right ? `col-md-6` : `col-12`}
          dangerouslySetInnerHTML={{ __html: left }}
        ></div>
        {right && <div className="col-md-6" dangerouslySetInnerHTML={{ __html: right }}></div>}
      </div>
    </div>
  </div>
);

export default TextRow;
