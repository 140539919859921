import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import ChiSonoTemplate from '../../components/template-chi-sono';

const ChiSonoPage = ({ data }) => {
  const { page } = data;

  return (
    <Layout data={data}>
      <Seo title={page.tagTitle || page.title} description={page.metaDescription} data={data} />
      <ChiSonoTemplate data={page} />
    </Layout>
  );
};

export default ChiSonoPage;
export const query = graphql`
  query ChisonoQuery {
    ...HeaderFragment
    ...SeoFragment
    page: chiSonoPageJson {
      title
      tagTitle
      metaDescription
      profile {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED, placeholder: TRACED_SVG)
        }
      }
      paragraphs {
        title
        hideTitle
        left
        right
      }
    }
  }
`;
